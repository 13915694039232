import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from '@react-spring/parallax'

import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements'

import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.png'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Helmet title="OzCalculators | Home" />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> Welcome to OzCalculators.
        </BigTitle>
        <Subtitle>Useful calculators for people down under.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Our Calculators</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Electricity Calculator"
            link="/electricitycalculator/"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Electricity Calculator comparing across different providers
          </ProjectCard>
          <ProjectCard
            title="BMI Calculator"
            link="/bmicalculator/"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Get on top of your health and find out more
          </ProjectCard>
          <ProjectCard
            title="EMI Calculator"
            link="/emicalculator/"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            Find out how much it takes for your dream home
          </ProjectCard>
          <ProjectCard
            title="Fuel Calculator"
            link="/fuelcalculator/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            All the fuel you need for your trip
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="OzCalculatos Logo" />
          <AboutSub>You one stop place for all the useful calculators</AboutSub>
        </AboutHero>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>Stay tuned for updates</ContactText>
        </Inner>
        <Footer>&copy; 2021 by OzCalculators</Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
